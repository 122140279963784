<template>
  <div
    v-if="
      config.public.site.domainfull === 'altinget.dk' &&
      (route.name?.toString().includes('job-names') ||
        route.name?.toString().includes('jobs'))
    "
  >
    <CommonListLabel
      :no-icon="true"
      label="Kommerciel partner"
      :translate-label="false"
    />
    <CommonDivider />
    <a v-if="type === 'genitor'" target="_blank" href="http://www.genitor.dk">
      <img
        alt="Genitor logo"
        src="https://legacy.altinget.dk/images/ads/genitor.png"
      />
    </a>
    <a
      v-else-if="type === 'mercuri'"
      target="_blank"
      href="https://www.mercuriurval.com/da-dk/"
    >
      <div class="p-2 border border-blue rounded-lg">
        <img
          alt="Mercuri logo"
          src="https://legacy.altinget.dk/images/ads/mercuri_urval_logo-removebg.png"
        />
      </div>
    </a>
  </div>
  <div v-else-if="editor" class="rounded-xl border p-4">
    <div v-if="showLabel" class="list-label opacity-50 mb-5">
      <template v-if="route.name?.toString().includes('debate')">
        {{ $t('EditorDebate') }}
      </template>
      <template v-else-if="route.name === 'names-index'">
        {{ $t('EditorNames') }}
      </template>
      <template v-else>
        {{
          $t('EditorFor', [
            store.currentPaper?.RecordId !== 1 ? store.currentPaper?.Name : '',
          ])
        }}:
      </template>
    </div>
    <template
      v-if="editor && editor.length === 1 && route.name !== 'names-index'"
    >
      <CommonEditorCardInfo :editor="editor[0]" />
      <NuxtLink
        v-if="
          aboutArticleId &&
          !route.name?.toString().includes('debate') &&
          !route.name?.toString().includes('job-names') &&
          !route.name?.toString().includes('jobs')
        "
        :to="{
          name: 'article',
          params: {
            id: aboutArticleId,
          },
        }"
        class="flex mt-2"
      >
        <CommonButton size="medium">
          {{ $t('AboutPortal') }}
        </CommonButton>
      </NuxtLink>
    </template>
    <template
      v-else-if="editor && editor.length === 2 && route.name !== 'names-index'"
    >
      <div class="flex">
        <div class="w-1/2">
          <CommonEditorCardInfo :editor="editor[0]" />
        </div>
        <div class="w-1/2">
          <CommonEditorCardInfo :editor="editor[1]" />
        </div>
      </div>
      <NuxtLink
        v-if="
          aboutArticleId &&
          !route.name?.toString().includes('debate') &&
          !route.name?.toString().includes('job-names') &&
          !route.name?.toString().includes('jobs')
        "
        :to="{
          name: 'article',
          params: {
            id: aboutArticleId,
          },
        }"
        class="mt-2 border whitespace-nowrap cursor-pointer inline-flex items-center transition-colors duration-100 select-none shrink-0 justify-center h-[30px] border-gray-300 bg-white hover:bg-gray-300 text-gray-700 hover:text-gray-900 button-m px-3 rounded-full"
      >
        {{ $t('AboutPortal') }}
      </NuxtLink>
    </template>
    <template v-if="route.name === 'names-index'">
      <CommonImageLoader
        class="rounded-full bg-black bg-opacity-10 w-15 h-15 overflow-hidden bg-cover bg-center"
        :src="'/A_Logomark.svg'"
        alt="Altinget logo"
        :width="15"
        :height="15"
      />
      <div class="list-label opacity-50 flex items-center mt-3">
        <img
          src="~/assets/icons/mail.svg?url"
          class="w-4 h-4 opacity-35 inline-block mr-1"
          :alt="$t('Email')"
        /><a :href="'mailto:' + config.public.site.namesEmail">{{
          config.public.site.namesEmail
        }}</a>
      </div>
    </template>
    <template v-if="route.name?.toString().includes('debate')">
      <CommonDivider class="-mx-4 px-4" />
      <div class="list-title-xs">
        {{ $t('DebateParticipate') }}
      </div>
      <div
        class="list-title-xxxs whitespace-pre-line"
        v-html="$t('DebateFormat')"
      />
      <div class="body-xs opacity-50 mt-2 mb-4">
        {{ $t('DebateDisclaimer') }}
      </div>
      <a :href="'mailto:' + config.public.site.debateEmail">
        <CommonButton bg="bg-blue" text="text-white" size="medium">
          {{ $t('DebateSend') }}
        </CommonButton>
      </a>
    </template>
    <template
      v-if="
        config.public.site.domainfull !== 'altinget.dk' &&
        (route.name?.toString().includes('job-names') ||
          route.name?.toString().includes('jobs'))
      "
    >
      <CommonDivider class="-mx-4 px-4" />
      <div class="list-title-xs">
        {{ $t('Advertise') }}
      </div>
      <div
        class="list-title-xxxs whitespace-pre-line"
        v-html="$t('AdvertiseProducts')"
      />
      <div class="body-xs opacity-50 mt-2 mb-4">
        {{ $t('AdvertiseContact') }}
      </div>
      <NuxtLink :to="{ name: 'ads' }">
        <CommonButton size="medium">
          {{ $t('AdvertiseMore') }}
        </CommonButton>
      </NuxtLink>
    </template>
  </div>
</template>

<script setup lang="ts">
import type { Editor } from '~/typesManual/content_api/paper'
import type { WriterFull } from '~/typesManual/content_api/article'

const store = useIndexStore()
const nuxtApp = useNuxtApp()
const config = useRuntimeConfig()
const route = useRoute()

const props = defineProps({
  id: {
    type: String,
    default: null,
  },
  showLabel: {
    type: Boolean,
    default: true,
  },
  type: {
    type: String,
    default: 'genitor',
  },
})
const editor = ref<Editor[] | WriterFull[] | null>()
const aboutArticleId = computed(() => {
  if (store.currentPaper && store.currentPaper.AboutPortal) {
    const oldString = store.currentPaper.AboutPortal
    return oldString.substring(oldString.lastIndexOf('/') + 1)
  } else {
    return ''
  }
})
if (props.id) {
  editor.value = [await nuxtApp.$api.content.writerFull(props.id)]
} else {
  editor.value = store.currentPaper?.Editor
}
</script>
